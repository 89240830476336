
@font-face{
    font-family: Biennale-Black;
    src: url("fonts/Biennale_Black.otf");
  }
  @font-face{
    font-family: Biennale-Bold;
    src: url("fonts/Biennale_Bold.otf");
  }
  @font-face{
    font-family: Biennale-Book;
    src: url("fonts/Biennale_Book.otf");
  }
  @font-face{
    font-family: Biennale-Light;
    src: url("fonts/Biennale_Light.otf");
  }
  @font-face{
    font-family: Biennale-Medium;
    src: url("fonts/Biennale_Medium.otf");
  }
  @font-face{
    font-family: Biennale-Regular;
    src: url("fonts/Biennale_Regular.otf");
  }
  @font-face{
    font-family: Biennale-SemiBold;
    src: url("fonts/Biennale_SemiBold.otf");
  }
  @font-face{
    font-family: Gloss_And_Bloom;
    src: url("fonts/Gloss_And_Bloom.ttf");
  }


/*-----------------------*/
/*--------------------*/

.Planbody {
    text-align: center;
  }

  .box-text-img-body{
    position: absolute;
    left: 29%;
    top: 70%;
     z-index: 1;
  
  }

  .text-img-1-body{
    font-family: "Biennale-Book";
    font-size: 450%;
    text-align: left;

 }

 .text-img-2-body{
  font-family: "Biennale-Black";
  font-size: 500%;
  text-align: left;
 }

 .text-img-3-body{
  font-family: "Biennale-Regular";
  font-size: 150%;
  text-align: center;
  padding-right: 0%;
  position: relative;
  right: 15%;
  
 }

  .icono-planbody{
    display: flex;
    padding-top: 3%;
  }

.seccion2body{
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding-top: 5%;
}

.box-text-body{
    text-align: left;
    display: grid;
}
.icono-planbody-reloj{
    display: grid;
    padding-left: 10%;
}
.box-reloj{
    display: flex;
    border-top: 1px solid white;
}
.title-icons-body{
 font-family: "Biennale-Black";
 font-size: 180%;
 margin: 0%;
 color: white;

}
.text-icons{
    font-family: "Biennale-Regular";
    font-size: 150%;
    color: white;
}
.title-reloj{
text-align: left;
font-size: 150%;
color: white;
}
.text-reloj{
text-align: center;
font-size: 150%;
padding-left: 5%;
font-family: "Biennale-Black";
color: white;
}

.icon-reloj{
    display: grid;
    padding-left: 7%;
}

/*---------------------boxprecios*/

.box-title-pack{
    padding-top: 4%;
}
.text-pack{
    font-family: "Biennale-Black";
    font-size: 230%;
    color: white;
}


.boxes-body{
display: flex;
justify-content: center;
}

.box-precios-body{
background-color: white;
padding: 6%;
margin: 2%;
}

.box-text-plans-body{
  background-color: #D9A88C;
  border-radius: 12px 10px 10px 10px;
  font-size: 60%;
  padding: 5%;
  color: rgb(255, 255, 255);
}

.text-precios-body{
    font-family: "Biennale-Black";
    font-size: 160%;
    color: black;
}

.text-plans-body{
    color: black;
    font-size: 130%;
}

.boxes-img-body{
    display: grid;
}

.box-imgs-body{
    display: flex;
}

/*--------------------------precios*/
.seccion3body{
    background-color: #D9A88C;
}
.boxes-precios-body{
    display: flex;
    justify-content: center;
    padding-top: 3%;
    color: aliceblue;
}

.title-masajes-faciales{
    font-family: "Biennale-Black";
    font-size: 200%;
    text-align: left;
}

.title-precios-body{
    font-family: "Biennale-Black";
    font-size: 160%;
    text-align: left;
}

.des-precios-body{
    font-family: "Biennale-Medium";
    font-size: 120%;
    text-align: left;
}
.des-precios-body-red{
    font-family: "Biennale-Medium";
    font-size: 110%;
    color: red;
    text-align: left;
}

.boxes-izq{
padding-right: 4%;
 border-right: 1px dashed white;
}

.boxes-der{
 padding-left: 11%;
 border-left: 1px dashed white;
}
@media (max-width: 460px){

  .box-text-img-body{
    position: absolute;
    left: 22%;
    top: 0%;
     z-index: 1;

  }

  .text-img-1-body{
    font-family: "Biennale-Book";
    font-size: 200%;
    text-align: left;

 }

 .text-img-2-body{
  font-family: "Biennale-Black";
  font-size: 200%;
  text-align: left;
 }

 .text-img-3-body{
  font-family: "Biennale-Regular";
  font-size: 100%;
  text-align: center;
  padding-right: 0%;
  position: relative;
  right: 15%;
  
 }


 /**/
 .seccion2body {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 5%;
}
 /**/



.boxes-body {
    display: grid;
    justify-content: initial;
    padding-top: 5%;
}

.boxes-precios-body {
  display: grid;
  
}
.boxes-izq{
  padding: 10%;
}
}