
@font-face{
    font-family: Biennale-Black;
    src: url("fonts/Biennale_Black.otf");
  }
  @font-face{
    font-family: Biennale-Bold;
    src: url("fonts/Biennale_Bold.otf");
  }
  @font-face{
    font-family: Biennale-Book;
    src: url("fonts/Biennale_Book.otf");
  }
  @font-face{
    font-family: Biennale-Light;
    src: url("fonts/Biennale_Light.otf");
  }
  @font-face{
    font-family: Biennale-Medium;
    src: url("fonts/Biennale_Medium.otf");
  }
  @font-face{
    font-family: Biennale-Regular;
    src: url("fonts/Biennale_Regular.otf");
  }
  @font-face{
    font-family: Biennale-SemiBold;
    src: url("fonts/Biennale_SemiBold.otf");
  }
  @font-face{
    font-family: Gloss_And_Bloom;
    src: url("fonts/Gloss_And_Bloom.ttf");
  }


/*-----------------------*/
/*--------------------*/

.Planclases {
    text-align: center;
  }

  .box-text-img-clas{
    position: absolute;
    left: 29%;
    top: 70%;
     z-index: 1;
  
  }

  .text-img-1-clas{
    font-family: "Biennale-Book";
    font-size: 450%;
    text-align: center;
    padding-right: 35%;
    

 }

 .text-img-2-clas{
  font-family: "Biennale-Black";
  font-size: 500%;
  text-align: center;
  padding-right: 35%;
 }

 .text-img-3-clas{
  font-family: "Biennale-Regular";
  font-size: 150%;
  text-align: center;
  padding-right: 0%;
  position: relative;
  right: 15%;
  
 }

  .icono-planclas{
    display: flex;
    padding-top: 3%;
  }

.seccion2clas{
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding-top: 5%;
}

.box-text-clas{
    text-align: left;
    display: grid;
}
.icono-planclas-reloj{
    display: grid;
    padding-left: 10%;
}
.box-reloj{
    display: flex;
    border-top: 1px solid white;
}
.title-icons-clas{
 font-family: "Biennale-Black";
 font-size: 180%;
 margin: 0%;
 color: white;

}
.text-icons{
    font-family: "Biennale-Regular";
    font-size: 150%;
    color: white;
}
.title-reloj{
text-align: left;
font-size: 150%;
color: white;
}
.text-reloj{
text-align: center;
font-size: 150%;
padding-left: 5%;
font-family: "Biennale-Black";
color: white;
}

.icon-reloj{
    display: grid;
    padding-left: 7%;
}
/*------------------------------------------*/


.tabla-horarios{
    color: #C6FF3B;
}

.linea-sup-dias{
    border-bottom: 8px solid #C6FF3B;
}
.box-horas-clas{
    border: none;
}
.th-box-gradient{
    background-image: linear-gradient(to bottom , rgba(255,0,0,0), #5f7b1a);
}

th{
    
    border-right: 1px solid rgb(49, 48, 48);
    border-left: 1px solid rgb(49, 48, 48) ;
    color: white;
}

td{
    border-top: none;
    border-bottom: none;
    border-right: 1px solid rgb(49, 48, 48);
    border-left: 1px solid rgb(49, 48, 48) ;
}
.td-border-green{
    border: 1px solid #C6FF3B;
    border-radius: 6px 6px 6px 6px;
}
/*------------------------------------------*/


.boxes-clas{
display: flex;
justify-content: center;
padding-top: 5%;
/*padding-bottom: ;
padding-left: ;
padding-right: ;*/
}

.box-precios-clas{
background-color: #272626;
padding: 4%;
margin: 1%;
}

.text-precios-clas{
  color: white;
    font-family: "Biennale-Black";
    font-size: 130%;
    
}

.text-plans-clas{
   color: white;
    font-size: 80%;
}

.text-clas-clas{
    color: white;
    font-size: 130%;
}

.box-text-plans-clas{
  background-color: rgb(65, 64, 64);
  border-radius: 12px 10px 10px 10px;
  font-size: 77%;
  padding: 1%;
  color: rgb(255, 255, 255);
}

.vigencia-box{
  color: white;
  font-size: 150%;
  padding-top: 2%;
  padding-bottom:4%;
}
@media (max-width: 460px){

  .box-text-img-clas{
    position: absolute;
    left: 22%;
    top: 7%;
     z-index: 1;

  }

  .text-img-1-clas{
    font-family: "Biennale-Book";
    font-size: 200%;
    text-align: center;
    margin-bottom: 0%;

 }

 .text-img-2-clas{
  font-family: "Biennale-Black";
  font-size: 200%;
  text-align: center;
  margin-bottom: 0%;
 }

 .text-img-3-clas{
  font-family: "Biennale-Regular";
  font-size: 100%;
  text-align: center;
  padding-right: 0%;
  position: relative;
  right: 15%;
  
 }


 /**/
 .seccion2clas {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 5%;
}
 /**/



.boxes-clas {
    display: grid;
    justify-content: initial;
    padding-top: 5%;
}
.tabla-horarios {
  display: grid;
}
}
