@font-face{
  font-family: Biennale-Black;
  src: url("fonts/Biennale_Black.otf");
}
@font-face{
  font-family: Biennale-Bold;
  src: url("fonts/Biennale_Bold.otf");
}
@font-face{
  font-family: Biennale-Book;
  src: url("fonts/Biennale_Book.otf");
}
@font-face{
  font-family: Biennale-Light;
  src: url("fonts/Biennale_Light.otf");
}
@font-face{
  font-family: Biennale-Medium;
  src: url("fonts/Biennale_Medium.otf");
}
@font-face{
  font-family: Biennale-Regular;
  src: url("fonts/Biennale_Regular.otf");
}
@font-face{
  font-family: Biennale-SemiBold;
  src: url("fonts/Biennale_SemiBold.otf");
}
@font-face{
  font-family: Gloss_And_Bloom;
  src: url("fonts/Gloss_And_Bloom.ttf");
}

.App {
  text-align: center;
}

.seccion1{
  background-color: rgb(0, 0, 0);
  padding: 0%;
  margin: 0%;
  color: white;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("imgrecursos2/menuresponsivo.png");
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  color: #C6FF3B;
}
.box-der-navs{
  padding-left: 5%;
}

.box-iconos-sup{
  display: flex;
  margin: 0%;
  padding: 0%;
}

/*.iconsup1{
  position: relative;
  
}
.iconsup2{
  position: relative;
  right: 10%;
}
.iconsup3{
  position: relative;
  right: 20%;
}
.iconsup4{
  position: relative;
  right: 26%;
}*/


.iconsup2{
  margin-left: -5%;
}
.iconsup3{
  margin-left: -5%;
}
.iconsup4{
  margin-left: -5%;
}


/*------------------------------------------------*/
.seccion3{
  display: flex;
  padding-top: 3%;
  padding-bottom: 5%;
  padding-right: 10%;
  padding-left: 10%;
  align-items: center;
  justify-content: center;
  background-image: url("imgrecursos2/lineas.jpg");
  background-size:100%; 
  background-repeat: no-repeat;
  
}

.titulo-servicios{
  padding-top: 3%;
  font-family: "Biennale-Regular";
}

.tit-servicios-1{
font-size: 300%;
color: #00204F;
font-family: "Biennale-Black";
}

.tit-servicios-2{
font-size: 200%;
color: #00204F;
font-family: "Biennale-Medium";
}

.text-ser-h1{
  font-size: 150%;
  font-family: "Biennale-Black";
  }

.text-ser-h2{
font-size: 80%;
font-family: "Biennale-Bold";
}

.text-ser-h3{
  font-size: 90%;
  font-family: "Biennale-Regular";
}

.columnaizq{
padding-right: 1%;
}

.columnader{
padding-left: 1%;
}

.filaentrenamiento{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6%;
}
/*.fila1-1, .fila1-2{
  border-right-style: dashed;
  border-bottom-style: dashed;
  border-color: #00204F;
}
.fila1-3{
  border-right-style: dashed;
  border-color: #00204F;
}
.fila2-1{
  border-bottom-style: dashed;
  border-color: #00204F;
}
.fila2-2{
  border-bottom-style: dashed;
  border-color: #00204F;
}*/

/*----------------------------*/

.seccion4{
  background-color: rgb(27, 25, 25);
  display: block;
}

.seccion4responsivo{
  background-color: rgb(27, 25, 25);
  display: none;
}

.box-coach{
  padding: 0% 33% 3% 33%;
}

.text-coaches{
  font-family: "Biennale-Black";
  font-size: 110%;
  display: flex;
  justify-content: center;
}

.text-coaches{
  font-family: "Biennale-Black";
  font-size: 240%;
  display: flex;
  justify-content: center;
  color: #fff;
  padding-top: 2%;
}

.coaches{
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-coaches{
  padding-top: 2%;
  padding-bottom: 3%;
  font-size: 200%;
  color: white;
}


.colorgreencoaches{
  color: #C6FF3B;
}

.text-coach1{
  display: flex;
}

.modal-header{
  border-bottom:0%
}

.popup-coach{
  display: flex;
}
.modal-header{
  display: flex;

}
.img-coachprin{
  transition: transform .2s; 
}
.img-coachprin:hover {
  transform: scale(1.1); 
}

.img-popup-responsive{
  display: none;
}

.title-coach{
  text-align: center;
  font-family: "Biennale-Black";
  font-size: 300%;
  padding-left: 29%;
  color: #1A1A1A;
}

.descripcion-coach{
  font-size: 85%;
  padding-left: 17%;
  padding-right: 3%;
  font-family: "Biennale-Medium";
}

.modal-body{
  display: flow-root;

}
.text-comillas{
  background-color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 5%;
  margin-left: 15%;
  margin-right: 15%;
  border-left: 5px solid #C6FF3B;
  font-family: "Biennale-Bold";
}

/*------------------------------------------*/

.react-3d-carousel .slider-container .slider-left div {
  background-image: url("imgrecursos2/flechaizquierda.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

  

.react-3d-carousel .slider-container .slider-right div {
background-image: url("imgrecursos2/flechaderecha.png");
background-size: 100% 100%;
background-repeat: no-repeat;
}

.carrousel{
  z-index: 1055;
}
/*--------------------------------------------*/



/*-----------------------------------------------*/

.seccion7{
  /*display: none;*/
  background-color: black;
  padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 15%;
    padding-right: 15%;
}

.containersplansmitad{
display: block;
display: contents;
padding: 5%;
color: white;
}
.text-title-puntos{
  font-family: "Biennale-Medium";
  border-bottom-style: groove;
  border-color: white;
  padding-left: 5%;
  text-align-last: left;
  font-size: 162%;  
}
.colorgreen{
  color: #C6FF3B;
  font-family: "Biennale-Bold";
}

.text-puntos{
  font-family: "Biennale-Light";
  text-align: left;
  padding-left: 5%;
  font-size: 100%;
  padding: 4%;
}

.text-puntos-clas-izq{
  font-family: "Biennale-Light";
  text-align: left;
  padding-left: 5%;
  font-size: 100%;
  padding: 4%;
  position: relative;
  right: 2%;
  
}

.text-puntos-clas-der{
  font-family: "Biennale-Light";
  text-align: left;
  padding-left: 5%;
  font-size: 100%;
  padding: 4%;
  position: relative;
  left: 2%;
}

.containersplans{
  display: none;
  padding: 5%;
  color: white;
}

.cont-plans{
  display: flex;
  
}
.cont-plans-mitad{
  display: flex;
}

.plans{
  background-color: #333333;
  margin: 2%;
  padding: 3%;
  
}

.container-doble-puntos{
  display: inline-flex;

}

.btn-primary {
  color: #fff;
  background-color: #26292e;
  border-color: #2b2f35;
  font-size: 122%;
  font-family: "Biennale-Light";

}

/*.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}*/

.box-vermas-trans{
  padding-top: 23%;
}
.box-vermas-ali{
  padding-top: 13%;
}

.box-vermas-body{
  padding-top: 0% ;
}

.box-vermas-teens{
  padding-top: 8%;
}

.box-vermas-clas{
  padding-top: 0%;
}

.box-vermas-day{
  padding-top: 21%;
}

/*-----------------------------------------------*/

.seccion8{
  background-color: #C6FF3B;
}
.contenedor-duda{
  padding-top: 2%;
  padding-bottom: 2%;
}
.text-dudas1{
  font-family: "Biennale-Black";
  font-size: 250%;
}

.text-dudas2{
  font-family: "Biennale-Regular";
  font-size: 150%;
}

/*-----------------------------------------------*/



.box-map-responsive{
  display: none;
}

.contenedor-ubicacion{
  display: flex;
  padding-top: 3%;
  padding-bottom: 3%;
}
.box-ubicacion{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.box-ubicacion-text{
  padding-top: 17%;
}
.box-map-ubicacion{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

/*-----------------------------------------------*/

.seccion10{
background-color: #333333;
padding-top: 1%;
padding-bottom: 1%;
}

.cont-redes{
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.box-redes{
  display: grid;
}

.box-redes-izq, .box-redes-der{
display: flex;
}

.box-icono{
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.img-icono-infe-map4{
position: relative;
left: -10%;
}

.text-icono-infe-map{
  position: relative;
  left: -16%;
  top: 11%;
}

.text-icono-infe{
  position: relative;
  right: 13%;
  top: 11%;
}
.box-legales{
  display: flex;
  align-items: center;
}

#box-legal {
  position: relative;
  
  left: 25%;
}
.icono-inf{
  padding: 0%;
}



.box-aviso  p{
  color: #fff;
}
.cont-redes {
  display: flex;
  padding: 0%; 
  padding-right: 0%;
}
/*-----------------------------------------------*/

@media (max-width: 460px){

  

.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    display: flow-root;
  }
  .navbar-light .navbar-toggler {
    color: rgba(247, 247, 247, 0.55);
    border-color: rgba(239, 239, 239, 0.55);
}

.enlaces-sup{
  display: none;
}
  /*seccion2*/
  /*seccion3*/
  .seccion3 {
    display: grid;
  background-image: none;
  }

    .fila1-1, .fila1-2 {
      border-right-style: none;
      border-bottom-style: dashed;
      border-color: #00204F;
  }

  .fila1-3{
    border-right-style: none;
    border-bottom-style: dashed;
    border-color: #00204F;
  }
.fila2-1{
  border-bottom-style: dashed;
  border-color: #00204F;
}
.fila2-2{
  border-bottom-style: dashed;
  border-color: #00204F;
}
  /*seccion4*/

  .seccion4responsivo{
    display: block;
  }

  .seccion4{
    display: none;
  }

  .coaches{
    display: grid;
  }
  .box-coach{
    padding: 8% 15% 9% 15%;
  }

  .text-coaches{
    font-size: 140%;
  }
.popup-coach{
  display: grid;
  justify-content: center;
  align-items: center;
}

.img-popup-responsive{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0% 0% 9% 0%;
}

.descripcion-coach {
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 85%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

.img-popup{
  display: none;
}

.title-coach {
  padding-left: 13%;
}

.img-coachprin{
  padding-top: 10%;
  padding-bottom: 10%;
}




/*seccion5*/

.react-3d-carousel {
  height: 134px;
}
/*seccion6*/
/*seccion7*/
.cont-plans {
  display: grid;
}

.cont-plans-mitad {
  display: grid;
}
/*seccion8*/
/*seccion9*/
.box-map-ubicacion{
  display: none;
}
.box-map-responsive{
  display: block;
}


/*seccion10*/

.contenedor-ubicacion {
  display: grid;
  padding-top: 3%;
  padding-bottom: 3%;
}

.cont-redes {
  display: grid;
  padding: 1%;
  padding-right: 10%;
}

.box-redes-izq, .box-redes-der {
  display: contents;
}
.img-icono-infe-1{
  position: relative;
  left: 2%;
}
.img-icono-infe-2{
  position: relative;
  left: 5%;
}
.img-icono-infe-3{
  position: relative;
  left: 4%;
}
.img-icono-infe-map4{
  position: relative;
  left: 0%;
  }

  #text-icono-infe-map-4{
    position: relative;
    left: -14%;
    top: 11%;
  }
  
  .text-icono-infe-map{
    position: relative;
    left: -4%;
    top: 11%;
  }
  
  .text-icono-infe{
    position: relative;
    right: 9%;
    
  }

  .box-legales{
    padding-top: 4%;
    padding-right: 12%;

  }
}

.box-legales {
  display: flex;
  align-items: center;
  justify-content: center;
}
