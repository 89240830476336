
@font-face{
    font-family: Biennale-Black;
    src: url("fonts/Biennale_Black.otf");
  }
  @font-face{
    font-family: Biennale-Bold;
    src: url("fonts/Biennale_Bold.otf");
  }
  @font-face{
    font-family: Biennale-Book;
    src: url("fonts/Biennale_Book.otf");
  }
  @font-face{
    font-family: Biennale-Light;
    src: url("fonts/Biennale_Light.otf");
  }
  @font-face{
    font-family: Biennale-Medium;
    src: url("fonts/Biennale_Medium.otf");
  }
  @font-face{
    font-family: Biennale-Regular;
    src: url("fonts/Biennale_Regular.otf");
  }
  @font-face{
    font-family: Biennale-SemiBold;
    src: url("fonts/Biennale_SemiBold.otf");
  }
  @font-face{
    font-family: Gloss_And_Bloom;
    src: url("fonts/Gloss_And_Bloom.ttf");
  }


/*-----------------------*/
/*--------------------*/

.Plantransformacion {
    text-align: center;
  }

  .img-sup-1{
    display: flex;
  }

  .box-text-img-trans{
    position: absolute;
    left: 29%;
    top: 70%;
     z-index: 1;

  }

  .text-img-1-trans{
    font-family: "Biennale-Book";
    font-size: 400%;
    text-align: left;

 }

 .text-img-2-trans{
  font-family: "Biennale-Black";
  font-size: 500%;
  text-align: left;
 }

 .text-img-3-trans{
  font-family: "Biennale-Regular";
  font-size: 200%;
  text-align: left;
  padding-right: 0%;
  position: relative;
  right: 15%;
  
 }

  .icono-plantrans{
    display: flex;
    padding-top: 3%;
  }

.seccion2trans{
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding-top: 5%;
}

.box-text-trans{
    text-align: left;
    display: grid;
}
.icono-plantrans-reloj{
    display: grid;
    padding-left: 10%;
}
.box-reloj{
    display: flex;
    border-top: 1px solid white;
}
.title-icons-trans{
 font-family: "Biennale-Black";
 font-size: 180%;
 margin: 0%;
 color: white;

}
.text-icons{
    font-family: "Biennale-Regular";
    font-size: 150%;
    color: white;
}
.title-reloj{
text-align: left;
font-size: 150%;
color: white;
}
.text-reloj{
text-align: center;
font-size: 150%;
padding-left: 5%;
font-family: "Biennale-Black";
color: white;
}

.icon-reloj{
    display: grid;
    padding-left: 7%;
}

.boxes-trans{
display: flex;
justify-content: center;
padding-top: 5%;
/*padding-bottom: ;
padding-left: ;
padding-right: ;*/
}

.box-precios-trans{
background-color: #272626;
padding: 6%;
margin: 2%;
}

.text-precios-trans{
    font-family: "Biennale-Black";
    font-size: 160%;
    color: white;
}

.text-plans-trans{
    color: white;
    font-size: 130%;
}

.box-text-plans-trans{
  background-color: rgb(65, 64, 64);
  border-radius: 12px 10px 10px 10px;
  font-size: 60%;
  padding: 5%;
  color: rgb(255, 255, 255);
}

a{
  text-decoration: none;
}

@media (max-width: 460px){

  .box-text-img-trans{
    position: absolute;
    left: 17%;
    top: 5%;
     z-index: 1;

  }

  .text-img-1-trans{
    font-family: "Biennale-Book";
    font-size: 200%;
    text-align: left;

 }

 .text-img-2-trans{
  font-family: "Biennale-Black";
  font-size: 200%;
  text-align: left;
 }

 .text-img-3-trans{
  font-family: "Biennale-Regular";
  font-size: 100%;
  text-align: center;
  padding-right: 0%;
  position: relative;
  right: 15%;
  
 }


 /**/
 .seccion2trans {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 5%;
}
 /**/



.boxes-trans {
    display: grid;
    justify-content: initial;
    padding-top: 5%;
}
}

